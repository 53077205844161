import React from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function StyledButton({ icon, text, href="#", target="_blank" }) {
    return (
        <a className="StyledButton" href={href} target={target}>
            <div className="flex">
                {text}
                <FontAwesomeIcon icon={icon} />
            </div>
        </a>
    );
}

export default StyledButton;
