export default {
    verticalCentered: false,
    scrollingSpeed: 1000,
    navigation: true,
    controlArrows: false,
    dragAndMove: true,
    slidesNavigation: true,
    responsiveHeight: 900,
    responsiveWidth: 1300,
    scrollOverflow: true,
    // scrollOverflowOptions: true,
    lazyLoading: true,
    scrollOverflowOptions: { disablePointer: true },
    licenseKey:
        "du$7;a`luXs8fz[ndsITq|Ip#=^s!rqtBI,fZ;P~Z<'S5piTI8e=uxS-Mw7@sx[+K9u860{O'Ymg",
};
